import React from 'react';
import '../../styles/footer.css';

export const Footer = () => {
  return (
    <footer className='footer'>
        Jesus Leccia &copy; Master React - 2024
    </footer>
  )
}
